import * as React from 'react';

function SvgFirstPage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#first_page_svg__clip0_1443:412)" fill="currentColor">
        <path d="M20.51 3.788L18.73 2 8.84 12l9.9 10 1.77-1.788L12.38 12l8.13-8.212zM4 2h3v20H4z" />
      </g>
      <defs>
        <clipPath id="first_page_svg__clip0_1443:412">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgFirstPage;
