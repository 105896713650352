import * as React from 'react';

function SvgMaster(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 5c-3.86 0-7 3.14-7 7s3.14 7 7 7c1.489 0 2.866-.47 4.002-1.266A6.95 6.95 0 0016 19c3.854 0 7-3.146 7-7s-3.146-7-7-7a6.95 6.95 0 00-3.998 1.266A6.953 6.953 0 008 5zm8 2c2.773 0 5 2.227 5 5s-2.227 5-5 5a4.968 4.968 0 01-2.498-.688 6.955 6.955 0 000-8.625A4.967 4.967 0 0116 7z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMaster;
