import * as React from 'react';

function SvgArrowForward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.23 20.23L8 22l10-10L8 2 6.23 3.77 14.46 12l-8.23 8.23z" fill="currentColor" />
    </svg>
  );
}

export default SvgArrowForward;
