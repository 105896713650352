import { styled } from '@linaria/react';
import * as colors from '../../styles/colors';

const levelColor = {
  info: {
    fg: colors.surface,
    bg: colors.subtext,
  },
  error: {
    fg: colors.surface,
    bg: colors.error,
  },
  success: {
    fg: colors.surface,
    bg: colors.success,
  },
  highlight: {
    fg: colors.surface,
    bg: colors.highlightPrimary,
  },
  lowlight: {
    fg: colors.text,
    bg: colors.highlight70,
  },
  brand: {
    fg: colors.text,
    bg: colors.brandPrimary,
  },
};

interface Props {
  level?: keyof typeof levelColor;
}

const Badge = styled.div<Props>`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  background-color: ${({ level = 'info' }) => levelColor[level].bg};
  color: ${({ level = 'info' }) => levelColor[level].fg};
`;

export default Badge;
