import React, { forwardRef, useState } from 'react';
import { styled } from '@linaria/react';
import ErrorIcon from '../Icon/ErrorOutline';
import {
  text,
  subtext,
  border,
  error,
  surface,
  disabledText,
  brandPrimary,
} from '../../styles/colors';
import rgba from 'polished/lib/color/rgba';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  as?: React.ElementType;
  prependAddon?: React.ReactNode | string;
  appendAddon?: React.ReactNode | string;
  isError?: boolean;
  toggleLabelRender?: (show: boolean) => React.ReactNode;
}

export const Input = (
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  const {
    type = 'text',
    as,
    prependAddon,
    appendAddon,
    isError = false,
    disabled = false,
    toggleLabelRender = (show) => (show ? `Hide` : `Show`),
    style,
    className,
    ...remain
  } = props;
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <InputWrapper isError={isError} disabled={disabled} style={style} className={className}>
      {prependAddon && <PrependWrapper>{prependAddon}</PrependWrapper>}
      <StyledInput as={as} type={show ? 'text' : type} ref={ref} disabled={disabled} {...remain} />
      {appendAddon && <AppendWrapper>{appendAddon}</AppendWrapper>}
      {isError && (
        <AppendWrapper isError>
          <ErrorIcon width={24} height={24} />
        </AppendWrapper>
      )}
      {type === 'password' && (
        <AppendWrapper>
          <StyledLink onClick={toggleShow} role="button">
            {toggleLabelRender(show)}
          </StyledLink>
        </AppendWrapper>
      )}
    </InputWrapper>
  );
};

const calculateBackgroundColor = ({ isError, disabled }: InputWrapperProps) => {
  if (isError) return rgba(error, 0.1);
  if (disabled) return border;
  return surface;
};

interface InputWrapperProps {
  isError?: boolean;
  disabled?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  border: solid 1px ${border};
  padding: 0.2rem 0.7rem;
  font-size: 1rem;
  transition: box-shadow 350ms ease;
  background-color: ${calculateBackgroundColor};

  &:focus-within {
    box-shadow: 0 0 0 2px ${brandPrimary};
  }

  &:active:not([disabled]),
  &:active:not(:disabled) {
    box-shadow: 0 0 0 2px ${text};
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover:not(:focus-within) {
    border-color: ${({ disabled }) => (disabled ? border : subtext)};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem 0;
  outline: none;
  color: ${text};
  background-color: transparent;

  &::placeholder {
    color: ${subtext};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:disabled,
  &[disabled] {
    color: ${disabledText};
    cursor: not-allowed;
  }
`;

interface AddonWrapperProps {
  isError?: boolean;
}

export const AddonWrapper = styled.div<AddonWrapperProps>`
  color: ${({ isError }) => (isError ? error : subtext)};
  flex-shrink: 0;
`;

export const PrependWrapper = styled(AddonWrapper)`
  padding: 0.5rem 0.5rem 0.5rem 0;
`;

export const AppendWrapper = styled(AddonWrapper)`
  padding: 0.5rem 0 0.5rem 0.5rem;
`;

const StyledLink = styled.a`
  color: ${text};
`;

export default forwardRef(Input);
