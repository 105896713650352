import * as React from 'react';

function SvgFlight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 22h2l5-8h5.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H15l-5-8H8l2.5 8H5L3.5 9H2l1 3.5L2 16h1.5L5 14h5.5L8 22z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFlight;
