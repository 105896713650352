import * as React from 'react';

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.626 3.904a6.472 6.472 0 102.415 11.541l4.148 4.149 1.409-1.41-4.149-4.148a6.471 6.471 0 00-3.823-10.132zM7.06 7.054a4.478 4.478 0 116.333 6.334A4.478 4.478 0 017.06 7.055z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSearch;
