import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import rgba from 'polished/lib/color/rgba';
import { text } from '../../styles/colors';
import { DROPDOWN } from '../../styles/zIndex';

export const Button = styled.button`
  width: 100%;
  /* sum to 1rem */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 0.5em;
  }

  span {
    flex-grow: 1;
    text-align: left;
  }

  &:focus {
    outline: none;
  }
`;

export const Title = styled.div`
  padding: 1em;
  font-weight: 600;
  cursor: default;

  ~ li {
    padding-left: 1.5em;
  }
`;

interface Props {
  isOpen: boolean;
}

export const Sheet = styled.div<Props>`
  position: absolute;
  top: 0;
  padding: 1rem 0;
  z-index: ${DROPDOWN};
  width: 100%;
  outline: none;
  display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
`;

export const cardStyle = css`
  box-shadow: 0 0.25em 0.75em ${rgba(text, 0.08)};
  padding: 0;
  max-height: 16em;
  overflow: auto;
`;
