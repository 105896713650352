import React, { forwardRef, Fragment } from 'react';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import rgba from 'polished/lib/color/rgba';
import * as Icon from '../Icon';
import Spinner from '../Spinner';
import { text, subtext, surface, brandPrimary } from '../../styles/colors';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'link' | 'icon';
  size?: 'xs' | 'sm' | 'md';
  iconName?: keyof typeof Icon;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  className?: string;
}

export const Button = (
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
): React.ReactElement => {
  const {
    variant = 'default',
    size = 'md',
    iconName = '',
    loading = false,
    children,
    disabled = false,
    block = false,
    className = ``,
    ...remain
  } = props;

  const iconSize = size === 'xs' ? 14 : 20;
  const iconColor = variant === 'primary' ? `${surface}` : `${text}`;
  const IconComp = iconName ? Icon[iconName] : Fragment;
  const prepend = iconName ? (
    <IconComp height={iconSize} width={iconSize} fill={iconColor} />
  ) : null;

  const styleMap = {
    default: defaultStyle,
    primary: primaryStyle,
    link: linkStyle,
    icon: iconStyle,
  };

  const style = styleMap[variant];

  return (
    <StyledButton
      type="button"
      size={size}
      disabled={loading || disabled}
      isLoading={loading}
      isIconOnly={!children}
      className={cx(style, className)}
      ref={ref}
      block={block}
      {...remain}
    >
      {loading ? <Spinner height={iconSize} width={iconSize} /> : prepend}
      {children && <span>{children}</span>}
    </StyledButton>
  );
};

interface BaseButtonProps {
  isLoading: boolean;
  size: 'xs' | 'sm' | 'md';
  isIconOnly: boolean;
  block: boolean;
}

const paddingMap = {
  xs: '0.25rem',
  sm: '0.75rem',
  md: '1rem',
};

const textBtnPaddingMap = {
  xs: '0.75em',
  sm: '0.75em 1em',
  md: '1em 2em',
};

export const fontSizeMap = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
};

const StyledButton = styled.button<BaseButtonProps>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  position: relative;
  display: inline-flex;
  border-color: ${text};
  align-items: center;
  justify-content: center;
  padding: ${({ size, isIconOnly }) => (isIconOnly ? paddingMap[size] : textBtnPaddingMap[size])};
  font-size: ${({ size }) => fontSizeMap[size]};
  line-height: 1.4;
  color: ${({ disabled, isLoading }) => (disabled || isLoading ? subtext : text)};
  font-weight: 500;
  cursor: ${({ disabled, isLoading }) => {
    if (isLoading) return `wait`;
    if (disabled) return `not-allowed`;
    return `pointer`;
  }};

  transition: background-color 250ms ease;

  /* reset */
  appearance: none;

  /* after: disabled border */
  &:after,
  /* before: focus outline */
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    box-sizing: border-box;
    border-color: transparent;
    transition: box-shadow 200ms ease;
    box-shadow: 0;
  }

  &:focus {
    outline: none;
    &:before {
      box-shadow: 0 0 0 2px ${brandPrimary};
    }
  }

  &:focus:before,
  &:focus-within:before {
    z-index: 1;
    box-shadow: 0 0 0 2px ${brandPrimary};
  }

  svg ~ span {
    margin-left: 0.5em;
  }
`;

const defaultStyle = css`
  background-color: transparent;
  border: 1px solid;

  &:hover:not([disabled]) {
    background-color: ${rgba(text, 0.05)};
  }

  &:active:not([disabled]) {
    &:before {
      box-shadow: 0 0 0 2px ${text}, 0 0 0 4px ${rgba(text, 0.05)};
    }
    background-color: ${rgba(text, 0.05)};
  }

  &:disabled,
  &[disabled] {
    background-color: ${rgba(text, 0.05)};
  }
`;

const primaryStyle = css`
  background-color: ${text};

  color: ${surface};

  &:hover:not([disabled]) {
    background-color: ${rgba(text, 0.85)};
  }

  &:active:not([disabled]) {
    &:before {
      box-shadow: 0 0 0 2px ${rgba(text, 0.05)};
    }
  }

  &:disabled,
  &[disabled] {
    background-color: ${rgba(text, 0.05)};
    color: ${subtext};
    border: solid 1px ${subtext};
  }
`;

const linkStyle = css`
  text-decoration: underline;
  transition: background-color 250ms ease, box-shadow 250ms ease;

  &:hover:not(:disabled):not(:active):not([disabled]) {
    background-color: ${rgba(text, 0.05)};
  }

  &:active:not([disabled]) {
    &:before {
      box-shadow: none;
    }
    background-color: ${rgba(text, 0.2)};
  }
`;

const iconStyle = css`
  transition: background-color 250ms ease, box-shadow 250ms ease;
  &:focus:before {
    box-shadow: none;
  }

  &:hover:not(:disabled):not(:active):not([disabled]) {
    background-color: ${rgba(text, 0.05)};
  }
`;
export default forwardRef(Button);
