import { styled } from '@linaria/react';
import { ListItem } from './ListItem';
import { border } from '../../styles/colors';

type Size = 'xs' | 'sm' | 'md';

const sizeMap: Record<Size, string> = {
  xs: '0',
  sm: '1em 0',
  md: '1em',
};

interface Props {
  size?: Size;
  divided?: boolean;
}

const List = styled.ul<Props>`
  display: block;

  ${ListItem} {
    padding: ${({ size }) => (size ? sizeMap[size] : sizeMap['md'])};

    &:not(:last-child) {
      border-bottom: ${({ divided }) => (divided ? `1px solid ${border}` : `0`)};
    }
  }
`;

export default List;
