import * as React from 'react';

function SvgExpandDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 8l7.59 7.59L19.18 8H4z" fill="currentColor" />
    </svg>
  );
}

export default SvgExpandDown;
