import * as React from 'react';

function SvgExitSign(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 3v14h2.823a.866.866 0 00.696-.349l.246-.324 1.529 1.175-.246.323C8.544 18.49 7.654 19 6.823 19H4v4h6.059l1.682-4.397a.873.873 0 00-.074-.774l-2.823-1.977a1.764 1.764 0 01-.458-2.349l1.857-3.254-.92-.153a.867.867 0 00-.666.048l-2.866 1.434L5 10l2.867-1.435c.641-.32 1.471-.509 2.15-.275l1.121.409 1.536.685c.53.237.969.653 1.234 1.17l.707.966c.152.295.407.48.738.48H18v2l-2.602-.002c-.995 0-1.896-.55-2.352-1.437l-.38-.564-1.577 2.843 1.749 1.573c.742.743.976 1.855.595 2.831L12 23h8V3H4zm9.764 6a1.765 1.765 0 110-3.53 1.765 1.765 0 010 3.53z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgExitSign;
