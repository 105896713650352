import React from 'react';
import { styled } from '@linaria/react';
import { subtext, error } from '../../styles/colors';
import { InputWrapper } from '../Input/Input';
import { Label } from './FormLabel';
import { GROUND } from '../../styles/zIndex';
import { PrependWrapper } from '../Input/Input';
import { Button as SelectButton } from '../Select/styled';

export interface FormControlProps {
  className?: string;
  helpMessage?: string | React.ReactElement;
  errorMessage?: string | React.ReactElement;
}

const FormControl: React.FC<FormControlProps> = (props) => {
  const { children, helpMessage, errorMessage, ...remain } = props;

  return (
    <Wrapper {...remain}>
      {children}
      {(errorMessage || helpMessage) && (
        <SubText isError={!!errorMessage}>{errorMessage || helpMessage}</SubText>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: column;

  ${Label} {
    position: absolute;
    z-index: ${GROUND + 1};
    top: 0.3rem;
    left: 0.75rem;
  }

  ${Label} ~ ${InputWrapper} > input,
  ${Label} ~ ${InputWrapper} > button,
  ${Label} ~ ${InputWrapper} > textarea,
  ${Label} ~ ${InputWrapper} > ${PrependWrapper}, ${Label} > ${SelectButton} {
    /* sum to 1rem */
    padding-top: 0.9rem;
    padding-bottom: 0.1rem;
  }
`;

interface SubTextProps {
  isError: boolean;
}

const SubText = styled.p<SubTextProps>`
  margin-top: 2px;
  font-size: 0.75rem;
  text-align: left;
  color: ${(props) => (props.isError ? error : subtext)};
  max-width: 480px;
  padding-left: 0.75rem;
`;

export default FormControl;
