import useThisBreakpoint from 'use-breakpoint';
import { md, xl } from '../styles/breakpoints';

const textToNumber = (s: string) => +s.replace('px', '');

const BREAKPOINTS = {
  mobile: 0,
  tablet: textToNumber(md),
  desktop: textToNumber(xl),
};

const useBreakpoint = (bp: 'mobile' | 'tablet' | 'desktop'): ReturnType<typeof useThisBreakpoint> =>
  useThisBreakpoint(BREAKPOINTS, bp);

export default useBreakpoint;
