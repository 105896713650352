import {
  useRef,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  DependencyList,
  AriaAttributes,
  Ref,
} from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const useEscapeHandler = (handler = noop, dependencies: DependencyList = []) => {
  const escapeHandler = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      handler();
    }
  };

  useEffect(() => {
    document?.addEventListener('keyup', escapeHandler);
    return () => document?.removeEventListener('keyup', escapeHandler);
  }, dependencies);
};

const useClickOutside = <E extends HTMLElement>(
  handler: (e: MouseEvent) => void = noop,
  dependencies: DependencyList,
) => {
  const callbackRef = useRef(handler);
  const ref = useRef<E>(null);
  const outsideClickHandler = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callbackRef.current(e);
    }
  };

  // useEffect wrapper to be safe for concurrent mode
  useEffect(() => {
    callbackRef.current = handler;
  });

  useEffect(() => {
    document?.addEventListener('click', outsideClickHandler);
    return () => document?.removeEventListener('click', outsideClickHandler);
  }, dependencies);

  return ref;
};

const style: CSSProperties = {
  position: 'absolute',
  top: 'auto',
  bottom: 'auto',
  left: 'auto',
  right: 'auto',
};

const role: AriaAttributes['aria-haspopup'] = 'dialog';

interface TriggerProps<T> {
  ref: Ref<T>;
  onClick(): void;
  'aria-haspopup': AriaAttributes['aria-haspopup'];
  'aria-expanded': boolean;
}

interface ContentProps<C> {
  ref: Ref<C>;
  role?: string;
  style?: CSSProperties;
}

interface PopoverReturn<T, C> {
  isOpen: boolean;
  triggerProps: TriggerProps<T>;
  contentProps: ContentProps<C>;
  setOpen(value: boolean): void;
}

interface Config {
  defaultOpen?: boolean;
  placement?: 'auto' | 'center';
  clickOutsideToClose?: boolean;
}

const usePopover = <T extends HTMLElement, C extends HTMLElement>({
  defaultOpen = false,
  placement = 'auto',
  clickOutsideToClose = false,
}: Config = {}): PopoverReturn<T, C> => {
  const [open, setOpen] = useState(defaultOpen);
  const toggle = useCallback(() => setOpen(!open), [open]);
  const close = useCallback(() => setOpen(false), []);

  const triggerRef = useRef<T>(null);
  const contentRef = useClickOutside<C>(open && clickOutsideToClose ? close : undefined, []);

  const [position, setPosition] = useState<CSSProperties>({
    top: 'auto',
    left: 'auto',
  });

  useEffect(() => {
    if (window && triggerRef.current && contentRef.current) {
      const contentPos = contentRef.current.getBoundingClientRect();
      const triggerPos = triggerRef.current.getBoundingClientRect();

      const projectedContentYPos = triggerPos.y + triggerPos.height + contentPos.height;
      const projectedContentXPos = triggerPos.x + contentPos.width;

      const downContentPos: CSSProperties = {
        top: triggerRef.current.clientHeight + triggerRef.current.offsetTop,
      };

      const upContentPos: CSSProperties = {
        bottom: triggerRef.current.clientHeight,
      };

      const leftContentAlign: CSSProperties = { left: 0 };

      const rightContentAlign: CSSProperties = { right: 0 };

      const centerContentPos: CSSProperties = {
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '100vh',
        overflowY: 'scroll',
      };

      const placeTop = projectedContentYPos > window.innerHeight;
      const placeRight = projectedContentXPos > window.innerWidth;

      const finalPos: CSSProperties =
        placement === 'center'
          ? centerContentPos
          : {
              ...(placeTop ? upContentPos : downContentPos),
              ...(placeRight ? rightContentAlign : leftContentAlign),
            };

      setPosition(finalPos);
    }
  }, [open, triggerRef, contentRef]);

  useEscapeHandler(close, []);

  const trigger = {
    ref: triggerRef,
    onClick: toggle,
    'aria-haspopup': role,
    'aria-expanded': open,
  };
  const content = {
    ref: contentRef,
    role,
    style: { ...style, ...position },
  };
  return {
    isOpen: open,
    triggerProps: trigger,
    contentProps: content,
    setOpen,
  };
};

export default usePopover;
