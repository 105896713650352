import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PopoverContext = React.createContext<(value: boolean) => void>(undefined!);

type PopoverProviderProps = { value: (value: boolean) => void; children: React.ReactNode };

export const PopoverProvider = ({ value, children }: PopoverProviderProps): JSX.Element => {
  return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>;
};

export const usePopoverOpen = (): ((value: boolean) => void) => {
  const context = React.useContext(PopoverContext);
  if (context === undefined) {
    throw new Error('usePopoverOpen must be used within a PopoverProvider');
  }
  return context;
};
