import { forwardRef } from 'react';
import { css } from '@linaria/core';
import ErrorIcon from '../Icon/ErrorOutline';
import { InputWrapper, AppendWrapper } from '../Input/Input';
import { text, subtext } from '../../styles/colors';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
}

const Textarea = (props: TextAreaProps, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
  const { isError, disabled, ...remain } = props;
  return (
    <InputWrapper isError={isError} disabled={disabled}>
      <textarea className={textareaStyle} ref={ref} disabled={disabled} {...remain} />
      {isError && (
        <AppendWrapper isError>
          <ErrorIcon width={24} height={24} />
        </AppendWrapper>
      )}
    </InputWrapper>
  );
};

const textareaStyle = css`
  width: 100%;
  padding: 0.5em 0;
  outline: none;
  color: ${text};
  resize: none;
  background: none;

  &::placeholder {
    color: ${subtext};
  }
`;

export default forwardRef(Textarea);
