import * as React from 'react';

function SvgPackage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 3v18h18V3h-7v5h-4V3H3z" fill="currentColor" />
    </svg>
  );
}

export default SvgPackage;
