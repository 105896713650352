import React, { useMemo, useState, forwardRef, Fragment } from 'react';
import { useSelect } from 'downshift';
import Card from '../../components/Card';
import { List, ListItem } from '../../components/List';
import Text from '../../components/Text';
import usePopover from '../../hooks/usePopover';
import SelectButton from './SelectButton';
import { Sheet, cardStyle, Title } from './styled';

interface SelectProps<T> {
  id: string;
  isError?: boolean;
  selectedItem?: T | null;
  defaultSelectedItem?: T | null;
  defaultLabel?: string;
  buttonLabelRender?(selectedItem: T): React.ReactNode;
  children: React.ReactNode;
  onChange?(selectedItem: T): void;
  className?: string;
  disabled?: boolean;
}

const flattenChildren = (arr: React.ReactElement[]): React.ReactElement[] =>
  arr.reduce((acc: React.ReactElement[], child) => {
    if (child.type === Option) return [...acc, child];
    if (child.type === OptGroup) return [...acc, ...flattenChildren(child.props.children)];
    return acc;
  }, []);

const convertOptions = (arr: React.ReactElement[]) =>
  arr.map((option) => ({ label: option.props.children, value: option.props.value }));

const Select = (props: SelectProps<any>, ref: React.Ref<HTMLButtonElement>) => {
  const {
    id,
    children,
    isError,
    onChange,
    selectedItem = null,
    defaultSelectedItem = null,
    defaultLabel = 'Select',
    buttonLabelRender = (item) => item?.label,
    disabled,
    ...remain
  } = props;
  const {
    isOpen,
    setOpen,
    triggerProps: { ref: triggerRef, onClick: onTriggerClick, ...trigger },
    contentProps: { ref: contentRef, ...content },
  } = usePopover<HTMLElement, HTMLDivElement>({ clickOutsideToClose: true });
  const [currentSelectedItem, setCurrentSelectedItem] = useState(defaultSelectedItem);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const items = React.Children.toArray(children);
  const flattenOptions = useMemo(
    () => convertOptions(flattenChildren(items as React.ReactElement[])),
    [children],
  );
  let index = -1;
  const selected = defaultSelectedItem ? currentSelectedItem : selectedItem;
  const { getLabelProps, getMenuProps, getToggleButtonProps, getItemProps } = useSelect({
    id,
    isOpen,
    items: flattenOptions.map((option) => option.value),
    selectedItem: selected,
    onSelectedItemChange: ({ selectedItem }) => {
      setOpen(false);
      if (!props.selectedItem) {
        setCurrentSelectedItem(selectedItem);
      }
      if (onChange) {
        onChange(selectedItem);
      }
    },
    onHighlightedIndexChange: ({ highlightedIndex }) => {
      // keep highlightedIndex in own state to avoid losing state on re-render
      if (highlightedIndex !== undefined && highlightedIndex > -1)
        setHighlightedIndex(highlightedIndex);
    },
  });

  const handleBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onTriggerClick();
  };

  const handleItemClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderOptions = (children: React.ReactElement[]) => {
    return React.Children.map(
      children,
      (child: React.ReactElement<OptGroupProps & { children: React.ReactElement[] }>) => {
        if (React.isValidElement(child)) {
          if (child.type === Option) {
            index++;
            return React.cloneElement(child, {
              ...child.props,
              active: highlightedIndex === index,
              ...getItemProps({
                item: flattenOptions[index]?.value,
                index,
                onClick: handleItemClick,
              }),
            });
          } else if (child.type === OptGroup) {
            return React.cloneElement(
              child,
              child.props,
              <Fragment>
                <Title>
                  <Text>{child.props.label}</Text>
                </Title>
                {renderOptions(child.props.children)}
              </Fragment>,
            );
          }
        }
        return null;
      },
    );
  };

  return (
    <Fragment>
      <span {...getLabelProps()} hidden>
        {defaultLabel}
      </span>
      <SelectButton
        isError={isError}
        disabled={disabled}
        ref={ref}
        {...getToggleButtonProps({
          ref: triggerRef as React.LegacyRef<HTMLButtonElement>,
        })}
        onClick={handleBtnClick}
        {...trigger}
        {...remain}
      >
        {selected
          ? buttonLabelRender(flattenOptions.find((option) => option.value === selected))
          : defaultLabel}
      </SelectButton>
      <Sheet
        isOpen={isOpen}
        {...getMenuProps({
          ref: contentRef,
          ...content,
        })}
      >
        <Card className={cardStyle}>
          <List>{renderOptions(children as React.ReactElement[])}</List>
        </Card>
      </Sheet>
    </Fragment>
  );
};

interface OptGroupProps {
  label: string;
}

export const OptGroup: React.FC<OptGroupProps> = (props) => <div {...props} />;

interface OptionProps extends React.LiHTMLAttributes<HTMLLIElement> {
  value: any;
}

export const Option = forwardRef((props: OptionProps, ref: React.Ref<HTMLLIElement>) => (
  <ListItem ref={ref} {...props} />
));

Option.displayName = 'Option';

export default forwardRef(Select);
