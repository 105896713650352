import * as React from 'react';

function SvgHandcart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.617 1.492L8.102 4.418l2.468 8.363 9.52-2.924-2.473-8.365zM5.5 2.852L1.695 4.068l.61 1.905 1.892-.604 3.907 12.393 13.785-4.336-.602-1.908L9.41 15.254 5.5 2.852zm9.5 1.56l.602 1.906-2.862.903-.601-1.907L15 4.412zm-4.002 13.59a2 2 0 100 4 2 2 0 000-4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHandcart;
