import React from 'react';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import rgba from 'polished/lib/color/rgba';
import { text, subtext } from '../../styles/colors';
import { TabsProps } from './Tabs';
import * as breakpoints from '../../styles/breakpoints';

interface TabProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  variant?: TabsProps['variant'];
  active: boolean;
}

const Tab: React.FC<TabProps> = (props) => {
  const { id, variant = 'default', active, ...remain } = props;

  const variantStyle = {
    default: defaultStyle,
    segment: segmentStyle,
  };

  return (
    <StyledTab
      role="tab"
      className={variantStyle[variant]}
      aria-selected={active}
      aria-controls={id}
      {...remain}
    />
  );
};

const StyledTab = styled.button`
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  &:focus {
    // TODO: focus style
    outline: none;
  }
`;

const defaultStyle = css`
  padding: 0.75rem;
  color: ${subtext};
  border-bottom: 2px solid transparent;

  &[aria-selected='true'] {
    color: ${text};
    border-bottom-color: ${text};
  }
`;

const segmentStyle = css`
  padding: 0.75rem;
  color: ${text};
  font-weight: 500;

  &[aria-selected='true'] {
    background-color: ${rgba(text, 0.1)};
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem;
  }
`;

export default Tab;
