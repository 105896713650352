import { styled } from '@linaria/react';
import { border } from '../../styles/colors';

const Separator = styled.hr`
  border-style: none;
  width: 100%;
  border-bottom: 1px solid ${border};
`;

export default Separator;
