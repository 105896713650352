import * as React from 'react';

function SvgVisa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4v16h20V4H2zm12.389 5c.62 0 1.343.31 1.343.31l-.31 1.24s-.517-.413-1.033-.413c-.723 0-.93.31-.93.62 0 .724 2.066.825 2.066 2.272 0 1.238-1.447 2.065-2.377 1.961-1.033 0-1.548-.308-1.548-.308l.207-1.24s.619.31 1.445.31c.826 0 .93-.312.93-.518 0-.93-2.067-.62-2.067-2.478 0-.93.724-1.756 2.274-1.756zm-6.508.104h1.445l-2.17 5.785H5.607L4.471 10.24s1.24.723 1.963 2.479c0 .207.103.517.103.517l1.344-4.132zm10.02 0h1.548l1.137 5.785h-1.24l-.207-.826h-1.86l-.308.826h-1.448L17.9 9.104zm-7.956.101h1.55l-.93 5.785H9.119l.826-5.785zm-7.23.002h2.273c.62 0 .826.516.826.516l.516 2.582c-.62-2.17-3.615-3.098-3.615-3.098zm15.701 1.652l-.826 2.17h1.238l-.412-2.17z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgVisa;
