import * as React from 'react';

function SvgLastPage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#last_page_svg__clip0_1479:415)" fill="currentColor">
        <path d="M4 3.788L5.78 2l9.89 10-9.9 10L4 20.212 12.13 12 4 3.788zM20.51 2h-3v20h3z" />
      </g>
      <defs>
        <clipPath id="last_page_svg__clip0_1479:415">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLastPage;
