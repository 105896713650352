import React, { forwardRef, useContext } from 'react';
import { styled } from '@linaria/react';
import { border, brandPrimary, text } from '../../styles/colors';
import { RadioContext } from './RadioGroup';

type RadioProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Radio = (props: RadioProps, ref: React.Ref<HTMLInputElement>): React.ReactElement => {
  const { id, children, title, disabled, className, style, ...remain } = props;
  const { value, defaultValue, onChange } = useContext(RadioContext);

  return (
    <Label htmlFor={id} title={title} disabled={disabled} className={className} style={style}>
      <Input
        type="radio"
        ref={ref}
        id={id}
        disabled={disabled}
        defaultChecked={
          typeof defaultValue !== 'undefined' ? defaultValue === props.value : undefined
        }
        checked={typeof value !== 'undefined' ? value === props.value : undefined}
        onChange={onChange}
        {...remain}
      />
      <Checkmark aria-hidden="true" />
      <Content>{children}</Content>
    </Label>
  );
};

interface LabelProps {
  disabled?: boolean;
}

const Label = styled.label<LabelProps>`
  display: inline-flex;
  position: relative;
  align-items: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Input = styled.input`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
`;

const Checkmark = styled.span`
  flex: 0 0 auto;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  padding: 0.125em;
  border: 2px solid ${border};
  border-radius: 50%;
  vertical-align: middle;

  ${Input}:not(:disabled):active ~ &,
  ${Input}:not(:disabled):focus ~ & {
    border-color: ${text};
    box-shadow: 0 0 0 2px ${brandPrimary};
  }

  ${Input}:checked ~ &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${brandPrimary};
    border-radius: 50%;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  margin-left: 0.5em;
`;

export default forwardRef(Radio);
