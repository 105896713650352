import * as React from 'react';

function SvgHeadset(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.99 12c-.11-5.37-4.31-9-8.99-9-4.61 0-8.85 3.53-8.99 9H2v6h3v-5.81c0-3.83 2.95-7.18 6.78-7.29a7.007 7.007 0 017.22 7V19h-8v2h10v-3h1v-6h-1.01z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHeadset;
