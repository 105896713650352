import * as React from 'react';

function SvgCustomsOfficer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 3s-3.793.766-5 1c-.543.105-1 .45-1 1v6c0 .55.457.902 1 1 1.191.215 5 1 5 1V7s-3.375-.762-4.5-1c-.27-.059-.5-.227-.5-.5s.23-.438.5-.5C5.613 4.746 9 4 9 4V3zm4 0l1 2h6V4l-7-1zm1 3l-1 1h1c0 1.648 1.352 3 3 3s3-1.352 3-3V6h-6zm1 5c-.566 0-1.074.242-1.438.625-.078.082-.156.156-.218.25L9.5 16l-2.031-2A119.5 119.5 0 004 13.281L9.5 19l3.5-3.781V18l6-7h-4zm5.844.188L13.875 19H20v1h-7v2h9v-9c0-.8-.469-1.496-1.156-1.813z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCustomsOfficer;
