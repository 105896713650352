import * as React from 'react';

function SvgSortUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 10.4L12.6 4l-6.4 6.4H19z" fill="currentColor" />
      <path opacity={0.2} d="M6.2 13.6l6.4 6.4 6.4-6.4H6.2z" fill="currentColor" />
    </svg>
  );
}

export default SvgSortUp;
