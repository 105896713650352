import * as React from 'react';

function SvgInfo1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="currentColor" d="M10 3h4v4h-4zM10.5 9h3v12h-3z" />
    </svg>
  );
}

export default SvgInfo1;
