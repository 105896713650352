import * as React from 'react';

function SvgAssignment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 4h-6.18C14.4 2.84 13.3 2 12 2c-1.3 0-2.4.84-2.82 2H3v18h18V4zm-9 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V8h10v2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAssignment;
