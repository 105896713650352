import React from 'react';
import { styled } from '@linaria/react';
import { rgba } from 'polished';
import Button from '../../components/Button';
import { success, error, surface, border, text, bgDark } from '../../styles/colors';
import { Checkmark, Info1, Unavailable } from '../Icon';

type Level = 'success' | 'error' | 'info';

const iconsMap = {
  info: <Info1 />,
  error: <Unavailable />,
  success: <Checkmark />,
};

const bgcolorMap = {
  info: { fg: text, bg: border },
  error: { fg: surface, bg: error },
  success: { fg: surface, bg: success },
};

const Container = styled.div`
  display: inline-flex;
  min-width: min(100%, 480px);
  padding: 0.75em;
  align-items: center;
  background-color: ${surface};
  border: solid 1px ${border};
  box-shadow: 0px 4px 16px ${rgba(bgDark, 0.1)};

  > *:not(:first-child) {
    margin-left: 0.75em;
  }
`;

const IconBg = styled.div<{ level: Level }>`
  padding: 0.375em;
  background-color: ${({ level = 'info' }) => bgcolorMap[level].bg};
  color: ${({ level = 'info' }) => bgcolorMap[level].fg};
  fill: ${surface};
`;

const Wrapper = styled.div`
  flex-grow: 1;
`;

interface Props {
  level: Level;
  onClose?(e: React.MouseEvent): void;
}

const Snackbar: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  level = 'info',
  children,
  onClose,
  ...remain
}) => {
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClose) onClose(e);
  };

  return (
    <Container {...remain}>
      <IconBg level={level}>{iconsMap[level]}</IconBg>
      <Wrapper>{children}</Wrapper>
      {onClose && (
        <Button
          variant="link"
          iconName="Delete"
          aria-label="Close"
          size="xs"
          onClick={handleClose}
        />
      )}
    </Container>
  );
};

export default Snackbar;
