import { styled } from '@linaria/react';
import * as breakpoints from '../../styles/breakpoints';

interface StackProps {
  gap?: 'sm' | 'md' | 'lg' | 'xl';
}

const mobileGap = {
  sm: '0.5rem',
  md: '0.75rem',
  lg: '1rem',
  xl: '1.5rem',
};

const desktopGap = {
  sm: '0.5rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
};

const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: ${({ gap = 'md' }) => mobileGap[gap]};
  }

  @media (min-width: ${breakpoints.md}) {
    > *:not(:first-child) {
      margin-top: ${({ gap = 'md' }) => desktopGap[gap]};
    }
  }
`;

export default Stack;
