import * as React from 'react';

function SvgGift(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 2C5.355 2 4 3.355 4 5c0 .352.074.684.188 1H4c-1.102 0-2 .898-2 2v2h20V8c0-1.102-.898-2-2-2h-.188A2.95 2.95 0 0020 5c0-1.645-1.355-3-3-3-1.594 0-2.832 1.012-3.813 2h-2.374C9.832 3.012 8.592 2 7 2zm0 2c.66 0 1.813.766 2.688 1.688.148.156.117.16.25.312H7c-.566 0-1-.434-1-1 0-.566.434-1 1-1zm10 0c.566 0 1 .434 1 1 0 .566-.434 1-1 1h-2.938c.133-.152.102-.156.25-.313C15.188 4.766 16.34 4 17 4zM3 11v9c0 1.102.898 2 2 2h14c1.102 0 2-.898 2-2v-9H3zm7.219 2c.73 0 1.383.48 1.781 1 .41-.52 1.05-1 1.781-1 1.23 0 2.219.965 2.219 2.156C16 16.895 12 20 12 20s-4-3.113-4-4.844C8 13.965 8.988 13 10.219 13z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGift;
