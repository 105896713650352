import * as React from 'react';

function SvgRemove(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 2L9 3H3v2h18V3h-6l-1-1h-4zM4.365 7l1.703 15h11.864l1.703-15H4.365z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRemove;
