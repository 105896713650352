import * as React from 'react';

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 13.286h-7.714V21h-2.572v-7.714H3v-2.572h7.714V3h2.572v7.714H21v2.572z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlus;
