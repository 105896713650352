import React, { Fragment, useCallback, createContext, useState, useMemo, useEffect } from 'react';
import { styled } from '@linaria/react';
import Dialog from '../../components/Dialog';
import usePopover from '../../hooks/usePopover';
import useBreakpoint from '../../hooks/useBreakpoint';
import { PopoverProvider } from '../../hooks/usePopover/context';
import * as BREAKPOINTS from '../../styles/breakpoints';
import { MODAL } from '../../styles/zIndex';
import { setFixedScrollPosition } from '../../utils/helpers';

const defaultContext = {
  /* eslint-disable @typescript-eslint/no-empty-function */
  setIcon: () => {},
  setTitle: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
};

interface ContextProps {
  setIcon: (value: React.ReactElement | undefined) => void;
  setTitle: (value: React.ReactElement | string) => void;
}

const ModalContext = createContext<ContextProps>(defaultContext);

export const useModal = () => {
  const context = React.useContext(ModalContext);
  return context;
};

interface Props {
  icon?: React.ReactElement;
  title: React.ReactElement | string;
  className?: string;
  trigger: React.ReactElement;
  disableClose?: boolean;
}

const Modal: React.FC<Props> = ({ icon, title, trigger, className, children, disableClose }) => {
  const {
    isOpen,
    setOpen,
    triggerProps,
    contentProps: { style: contentPos, ...contentProps },
  } = usePopover<HTMLElement, HTMLDivElement>({ placement: 'center' });
  const { breakpoint } = useBreakpoint('mobile');
  const [modalIcon, setModalIcon] = useState(icon);
  const [modalTitle, setModalTitle] = useState(title);

  useEffect(() => {
    if (!isOpen) {
      // reset to default value after close
      setModalIcon(icon);
      setModalTitle(title);
    }
  }, [isOpen]);

  const dialogRef = useCallback((node) => {
    const isOpen = !!node;
    setFixedScrollPosition(isOpen);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const contents = (
    <Dialog
      ref={dialogRef}
      icon={modalIcon}
      title={modalTitle}
      className={className}
      onClose={disableClose ? undefined : handleClose}
    >
      {children}
    </Dialog>
  );

  const sheets: Record<string, JSX.Element> = {
    mobile: <Sheet {...contentProps}>{contents}</Sheet>,
    tablet: (
      <Sheet {...contentProps} style={contentPos}>
        {contents}
      </Sheet>
    ),
    desktop: (
      <Sheet {...contentProps} style={contentPos}>
        {contents}
      </Sheet>
    ),
  };

  const contextValue = useMemo(
    () => ({
      setTitle: setModalTitle,
      setIcon: setModalIcon,
    }),
    [setModalTitle, setModalIcon],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <PopoverProvider value={setOpen}>
        <Wrapper>
          {React.cloneElement(trigger, triggerProps)}
          {isOpen && (
            <Fragment>
              <Overlay />
              {sheets[breakpoint || 'mobile']}
            </Fragment>
          )}
        </Wrapper>
      </PopoverProvider>
    </ModalContext.Provider>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: ${MODAL};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.3);
`;

const Sheet = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${MODAL + 1};
  min-width: 20rem;
  max-height: 90vh;
  overflow: auto;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding: 0.5rem 0;
    max-height: none;
    overflow: initial;
  }
`;

export default Modal;
