import React, { forwardRef } from 'react';
import { styled } from '@linaria/react';
import { surface, border, error } from '../../styles/colors';
import * as breakpoints from '../../styles/breakpoints';

interface Props {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  errorMessage?: string | React.ReactElement;
}

const Card = (props: Props, ref: React.Ref<HTMLDivElement>): React.ReactElement => {
  const { errorMessage, children, ...remain } = props;
  return (
    <Container ref={ref} {...remain}>
      {children}
      {errorMessage && <Subtext>{errorMessage}</Subtext>}
    </Container>
  );
};

const Container = styled.div`
  display: block;
  background-color: ${surface};
  border: solid 1px ${border};
  padding: 1rem;

  &:hover {
    border-color: ${border};
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 1.5rem;
  }
`;

const Subtext = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  text-align: left;
  color: ${error};
`;

export default forwardRef(Card);
