import { styled } from '@linaria/react';
import { surface, brandSecondary } from '../../styles/colors';

interface ListItemProps {
  active?: boolean;
  onClick?: unknown;
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ active }) => (active ? brandSecondary : surface)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

export default ListItem;
