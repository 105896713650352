import React from 'react';
import { styled } from '@linaria/react';
import usePopover from '../../hooks/usePopover';
import useBreakpoint from '../../hooks/useBreakpoint';
import { PopoverProvider } from '../../hooks/usePopover/context';

import * as BREAKPOINTS from '../../styles/breakpoints';
import { MODAL } from '../../styles/zIndex';

interface Props {
  trigger: React.ReactElement;
}

const Popover: React.FC<Props> = ({ trigger, children }) => {
  const {
    isOpen,
    setOpen,
    triggerProps,
    contentProps: { style: contentPos, ...contentProps },
  } = usePopover<HTMLElement, HTMLDivElement>({ clickOutsideToClose: true });
  const { breakpoint } = useBreakpoint('mobile');

  const sheets: Record<string, JSX.Element> = {
    mobile: <Sheet {...contentProps}>{children}</Sheet>,
    tablet: (
      <Sheet {...contentProps} style={contentPos}>
        {children}
      </Sheet>
    ),
    desktop: (
      <Sheet {...contentProps} style={contentPos}>
        {children}
      </Sheet>
    ),
  };

  return (
    <PopoverProvider value={setOpen}>
      <Wrapper>
        {React.cloneElement(trigger, triggerProps)}
        {isOpen && sheets[breakpoint || 'mobile']}
      </Wrapper>
    </PopoverProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Sheet = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${MODAL + 1};
  max-height: 90vh;
  overflow: auto;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding: 0.5rem 0;
    max-height: none;
    overflow: initial;
  }
`;

export default Popover;
