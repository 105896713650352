import * as React from 'react';

function SvgAppleLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.62 2.014a.447.447 0 00-.233.006c-.884.262-1.774.87-2.381 1.634-.547.702-1.016 1.749-1.006 2.844.003.316.283.56.582.49.904-.21 1.822-.911 2.443-1.693.531-.78.914-1.72.967-2.756a.505.505 0 00-.373-.525zM8.353 7C6.73 7 4.99 7.978 3.945 9.5c-1.508 2.174-1.276 6.305 1.16 9.783C6.035 20.587 7.193 22 8.701 22c1.392 0 1.74-.87 3.598-.87 1.857 0 2.204.87 3.596.87 1.623 0 2.784-1.522 3.712-2.826a7.964 7.964 0 001.012-1.594.855.855 0 00-.35-1.129c-2.632-1.466-3.114-4.966-.837-6.904.422-.36.458-1.015.015-1.348C18.451 7.45 17.313 7 16.244 7c-1.857 0-2.668 1-3.945 1-1.277 0-2.321-1-3.945-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAppleLogo;
