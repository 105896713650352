/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { css, cx } from '@linaria/core';
import useBreakpoint from '../../hooks/useBreakpoint';
import Button from '../Button';
import ButtonGroup from '../Button/ButtonGroup';

interface Props {
  pageSize?: number;
  size?: 'xs' | 'sm' | 'md';
  currentPage?: number;
  lastPage: number;
  rootPath?: string;
  link?: React.ReactNode;
  hideLastButton?: boolean;
  loading?: boolean;
}

const Pagination: React.FC<Props> = (props) => {
  const { breakpoint } = useBreakpoint('mobile');
  const { lastPage, rootPath, link, hideLastButton, loading } = props;
  const [defaultCurrentPage, setDefaultCurrentPage] = useState(1);

  const defaultSize = breakpoint === 'mobile' ? 'xs' : 'sm';
  const defaultPageSize = breakpoint === 'mobile' ? 5 : 10;

  const size = props.size ?? defaultSize;
  const pageSize = props.pageSize ?? defaultPageSize;

  const currentPage = props.currentPage ?? defaultCurrentPage;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  const sizeMap = {
    xs: xs,
    sm: sm,
    md: md,
  };

  const buttonProps = {
    size,
    as: link,
    className: cx(btnStyle, sizeMap[size || defaultSize]),
    variant: 'link',
  };

  const lowerSize = Math.ceil((pageSize - 1) / 2);
  const upperSize = pageSize - lowerSize - 1;

  let startPage = Math.max(1, currentPage - lowerSize);
  let endPage = Math.min(lastPage, currentPage + upperSize);

  const goToPage = (targetPage: number): void => {
    if (targetPage < 1 || targetPage > lastPage) return;

    setDefaultCurrentPage(targetPage);
  };

  let diff = endPage - startPage;

  while (diff < pageSize - 1) {
    if (startPage > 1) {
      startPage--;
      diff++;
    } else if (endPage < lastPage) {
      endPage++;
      diff++;
    } else {
      break;
    }
  }

  if (!lastPage || lastPage === 1) return null;

  return (
    <ButtonGroup size={size}>
      {!hideLastButton && (
        <Button
          {...buttonProps}
          iconName="FirstPage"
          aria-label="First Page"
          // @ts-ignore
          to={`${rootPath}${1}`}
          onClick={() => goToPage(1)}
          disabled={loading || isFirstPage}
        />
      )}
      <Button
        {...buttonProps}
        iconName="ArrowBack"
        aria-label="Previous"
        // @ts-ignore
        to={`${rootPath}${currentPage - 1}`}
        onClick={() => goToPage(currentPage - 1)}
        disabled={loading || isFirstPage}
      />
      {Array.from(Array(endPage - startPage + 1), (_, i) => (
        <Button
          {...buttonProps}
          key={`page_${i + startPage}`}
          // @ts-ignore
          to={`${rootPath}${i + startPage}`}
          variant={currentPage === i + startPage ? 'primary' : 'link'}
          onClick={() => goToPage(i + startPage)}
          disabled={loading}
        >
          {i + startPage}
        </Button>
      ))}
      <Button
        {...buttonProps}
        iconName="ArrowForward"
        aria-label="Next"
        // @ts-ignore
        to={`${rootPath}${currentPage + 1}`}
        onClick={() => goToPage(currentPage + 1)}
        disabled={loading || isLastPage}
      />
      {!hideLastButton && (
        <Button
          {...buttonProps}
          iconName="LastPage"
          aria-label="Last Page"
          // @ts-ignore
          to={`${rootPath}${lastPage}`}
          onClick={() => goToPage(lastPage)}
          disabled={loading || isLastPage}
        />
      )}
    </ButtonGroup>
  );
};

const btnStyle = css`
  &:focus:before,
  &:focus-within:before {
    box-shadow: none;
  }
`;

const xs = css`
  width: 2.125rem;
  height: 2.125rem;
`;

const sm = css`
  width: 2.75rem;
  height: 2.75rem;
`;

const md = css`
  width: 3.375rem;
  height: 3.375rem;
`;

export default Pagination;
