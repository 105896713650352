import { styled } from '@linaria/react';

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface TextProps {
  size?: Size;
}

const sizeMap: Record<Size, string> = {
  sm: '1rem',
  md: '1.5rem',
  lg: '2.5rem',
  xl: '3rem',
  xxl: '4rem',
};

const Heading = styled.h1<TextProps>`
  font-size: ${({ size }) => (size ? sizeMap[size] : '1rem')};
  font-weight: 500;
`;

export default Heading;
