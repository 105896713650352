import * as React from 'react';

function SvgAmericanExpress(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4v16h20V4H2zm2.688 6h1.238L7.5 13.8V10h1.574l1.125 2.7 1.125-2.7h1.463v4h-.9v-2.9L10.65 14h-.9l-1.236-3v3H6.6l-.336-.9H4.35l-.336.9H3l1.688-4zm9.337 0h3.711l.9 1.2 1.126-1.2H21l-1.688 2L21 14h-1.125l-1.125-1.3-1.125 1.3h-3.6v-4zm-8.775.8l-.563 1.4h1.125l-.562-1.4zm9.676.1v.7h2.023v.8h-2.023v.8h2.136l1.012-1.2-.898-1.1h-2.25z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAmericanExpress;
