import * as React from 'react';

function SvgSortDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 13.6l6.4 6.4 6.4-6.4H6z" fill="currentColor" />
      <path opacity={0.2} d="M18.8 10.4L12.4 4 6 10.4h12.8z" fill="currentColor" />
    </svg>
  );
}

export default SvgSortDown;
