import { styled } from '@linaria/react';
import { subtext } from '../../styles/colors';

type Size = 'sm' | 'md' | 'lg';

export interface TextProps {
  size?: Size;
}

const sizeMap: Record<Size, string> = {
  sm: '0.875rem',
  md: '1.5rem',
  lg: '2.215rem',
};

const Text = styled.p<TextProps>`
  font-size: ${({ size }) => (size ? sizeMap[size] : '1rem')};

  &.subtext {
    color: ${subtext};
  }
`;

export default Text;
