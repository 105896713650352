import * as React from 'react';

function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 2v9H6l6 6 6-6h-4V2h-4zM2 20v2h20v-2H2z" fill="currentColor" />
    </svg>
  );
}

export default SvgDownload;
