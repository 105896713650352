import * as React from 'react';

function SvgSend(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#send_svg__clip0_1001:4)">
        <path
          d="M13.735 23.57l8.478-21.206-21.206 8.478L5.95 15.8l12.02-9.192-9.192 12.02 4.957 4.943z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="send_svg__clip0_1001:4">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSend;
