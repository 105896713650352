// Text
export const text = '#000000';
export const surface = '#FFFFFF';
export const border = '#E5E5E5';
export const subtext = '#717171';
export const bgDark = '#000000';

// Brand
export const brandPrimary = '#17F082';
export const brandSecondary = '#F0F3F4';

// Highlight
export const highlight70 = '#FCC269';
export const highlight60 = '#FCAD36';
export const highlightPrimary = '#FB9804';
export const highlight45 = '#E28903';

// Status
export const success = '#0CA759';
export const error = '#F0442E';

// TODO: To be added
export const disabledText = '#919191';
