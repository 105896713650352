import * as React from 'react';

function SvgFilter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 3v2h18V3H3zm2 4l5 6v8h4v-8l5-6H5z" fill="currentColor" />
    </svg>
  );
}

export default SvgFilter;
