import React, { ReactElement } from 'react';
import { styled } from '@linaria/react';
import Button, { ButtonProps } from '../Button';

const Wrapper = styled.div`
  display: inline-flex;
  > :not(:last-child) {
    border-right: none;
  }
`;

interface Props {
  size?: ButtonProps['size'];
  className?: string;
}

const ButtonGroup: React.FC<Props> = ({ children, size = 'md', className, ...remain }) => {
  if (!children) {
    return null;
  }
  const childBtns = React.Children.map(children, (child = <div />) => {
    const btn = child as ReactElement;

    // For Storybook Bullshit https://github.com/mdx-js/mdx/issues/785
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isStorybookShit = btn?.type.displayName === 'MDXCreateElement';
    if (!isStorybookShit && btn?.type && btn.type !== Button) {
      console.warn(`[ButtonGroup]: Child "${btn.type}" is not of type "Button" `);
    }
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { size });
    }
    return child;
  });

  return (
    <Wrapper className={className} {...remain}>
      {childBtns}
    </Wrapper>
  );
};

export default ButtonGroup;
