import { styled } from '@linaria/react';
import { subtext } from '../../styles/colors';

export const Label = styled.label`
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${subtext};
`;

export default Label;
