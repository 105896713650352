import React, { createContext } from 'react';
import Radio from './Radio';

interface RadioContext {
  value?: string;
  defaultValue?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export const RadioContext = createContext<RadioContext>({});

interface RadioGroupProps {
  name?: string;
}

const RadioGroup: React.FC<RadioContext & RadioGroupProps> = (props) => {
  const { name, value, defaultValue, onChange, children, ...remain } = props;

  if (!children) return null;

  return (
    <RadioContext.Provider value={{ value, defaultValue, onChange }} {...remain}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === Radio) {
          return React.cloneElement(child, { name });
        }
        return child;
      })}
    </RadioContext.Provider>
  );
};

export default RadioGroup;
