import * as React from 'react';

function SvgWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23 20L12 1.7 1 20h22zm-10-2h-2v-2h2v2zm0-4h-2V9h2v5z" fill="currentColor" />
    </svg>
  );
}

export default SvgWarning;
