import * as React from 'react';

function SvgDroppedParcel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1zm4 0a1 1 0 00-1 1v4.076c0 .538.452.93.99.928h.022c.537 0 .988-.393.988-.93V2a1 1 0 00-1-1zm4 0c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1zm-4.002 8c-.322 0-.645.08-.943.238L4.52 12.815a1 1 0 00-.52.876v4.616c0 .365.199.702.52.877l6.546 3.584c.291.155.61.232.93.232.324 0 .651-.08.951-.238l6.534-3.576a1 1 0 00.521-.877v-4.616a1 1 0 00-.521-.877l-6.547-3.584A1.992 1.992 0 0011.998 9zM6 15.318l5 2.534v2.601l-5-2.738v-2.397zm12 0v2.399l-5 2.748V17.85l5-2.532z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDroppedParcel;
