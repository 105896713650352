import * as React from 'react';

function SvgOpenBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 3l-3 3-3-3-9 4.088 3 3.26v7.593l8 4.058v-9.084l-6-2.801 6.591-3.016a.902.902 0 01.816 0L19 10.113l-6 2.801V22l8-4.061v-7.592l3-3.26L15 3z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgOpenBox;
