import React, { Fragment } from 'react';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { cx } from '@linaria/core';
import { border } from '../../styles/colors';

interface SkeletonProps {
  isLoaded?: boolean;
  width?: string;
  height?: string;
  className?: string;
}

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const { isLoaded = false, width, height, className, children } = props;
  if (isLoaded) return <Fragment>{children}</Fragment>;

  return <Block className={cx(pulseStyle, className)} style={{ width, height }} />;
};

const Block = styled.div`
  background-color: ${border};
  border-radius: 2px;
  width: 5rem;
  max-width: 100%;
  height: 1rem;
`;

const pulseStyle = css`
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

export default Skeleton;
