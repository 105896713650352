import * as React from 'react';

function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 2v16h2V4h14V2H2zm4 4v16h16V6H6zm2 2h12v12H8V8z" fill="currentColor" />
    </svg>
  );
}

export default SvgCopy;
