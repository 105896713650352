import { forwardRef } from 'react';
import { styled } from '@linaria/react';
import { text, border, surface, brandPrimary } from '../../styles/colors';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

const Checkbox = (props: CheckboxProps, ref: React.Ref<HTMLInputElement>): React.ReactElement => {
  const { indeterminate, name, children, title, ...remain } = props;
  return (
    <Label htmlFor={name} title={title}>
      <Input type="checkbox" name={name} id={name} ref={ref} {...remain} />
      {indeterminate ? <Indeterminate /> : <Checkmark />}
      <Text>{children}</Text>
    </Label>
  );
};

const Label = styled.label`
  display: inline-flex;
  position: relative;
  align-items: center;
  user-select: none;
  text-align: left;
`;

const Input = styled.input`
  width: 0;
  opacity: 0;
`;

const Indeterminate = styled.span`
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid ${border};
  background-color: ${surface};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${brandPrimary};
    margin: 2px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 3px;
    background-color: ${text};
  }
`;

const Checkmark = styled.span`
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid ${border};
  background-color: ${surface};
  cursor: pointer;

  ${Input}:checked ~ &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${brandPrimary};
    margin: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 8px;
    display: none;
    width: 5px;
    height: 9px;
    border: 0 solid ${text};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${Input}:checked ~ &:after {
    display: block;
  }

  ${Input}:disabled ~ & {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${Input}:not(:disabled):active ~ &,
  ${Input}:not(:disabled):focus ~ & {
    border-color: ${text};
    box-shadow: 0 0 0 2px ${brandPrimary};
  }
`;

const Text = styled.span`
  cursor: pointer;
  font-size: 1em;
  margin-left: 2em;

  ${Input}:disabled ~ & {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default forwardRef(Checkbox);
