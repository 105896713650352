import React, { forwardRef } from 'react';
import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import rgba from 'polished/lib/color/rgba';
import Button from '../../components/Button';
import Card from '../../components/Card';
import { text } from '../../styles/colors';
import * as BREAKPOINTS from '../../styles/breakpoints';
import useBreakpoint from '../../hooks/useBreakpoint';

interface Props {
  icon?: React.ReactElement;
  title: string | React.ReactElement;
  onClose?(): void;
  className?: string;
  children: React.ReactNode;
}

const Dialog = (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { icon, title, onClose, className, children, ...remain } = props;
  const { breakpoint } = useBreakpoint('mobile');

  const size: Record<string, 'xs' | 'sm'> = {
    mobile: 'xs',
    tablet: 'sm',
    desktop: 'sm',
  };

  return (
    <Card className={cx(cardStyle, className)} ref={ref} {...remain}>
      <Header>
        <Spacer>
          {icon}
          <Heading>{title}</Heading>
        </Spacer>
        {onClose && (
          <Button
            variant="link"
            iconName="Delete"
            aria-label="Close"
            size={size[breakpoint || 'mobile']}
            onClick={onClose}
          />
        )}
      </Header>
      {children}
    </Card>
  );
};

const cardStyle = css`
  width: 100%;
  padding-bottom: calc(1rem + var(--safe-area-inset-bottom));
  box-shadow: 0 0.25em 1em ${rgba(text, 0.1)};

  @media (min-width: ${BREAKPOINTS.md}) {
    min-width: 28rem;
    padding-bottom: 1.5rem;
  }
`;

const Heading = styled.h3`
  font-size: 1rem;
  font-weight: 500;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: 1.5rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.25em;
  padding-bottom: 0.75em;
`;

const Spacer = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export default forwardRef(Dialog);
