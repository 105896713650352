import React, { forwardRef } from 'react';
import { ArrowDown, ErrorOutline } from '../../components/Icon';
import { InputWrapper, AddonWrapper } from '../../components/Input/Input';
import { Button } from './styled';

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  isError?: boolean;
  disabled?: boolean;
}

export const SelectButton = (props: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const { isError, children, disabled, ...remain } = props;

  return (
    <InputWrapper isError={isError} disabled={disabled} {...remain}>
      <Button type="button" disabled={disabled} ref={ref}>
        <span>{children}</span>
        {isError && (
          <AddonWrapper isError>
            <ErrorOutline width={24} height={24} />
          </AddonWrapper>
        )}
        <ArrowDown />
      </Button>
    </InputWrapper>
  );
};

export default forwardRef(SelectButton);
