import * as React from 'react';

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.23 17.77L22 16 12 6 2 16l1.77 1.77L12 9.54l8.23 8.23z" fill="currentColor" />
    </svg>
  );
}

export default SvgArrowUp;
