import * as React from 'react';

function SvgKitchenScales(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 2c0 1.333.814 2.275 1.553 2.645C6.29 5.014 7 5 7 5h4v2.076c-3.944.522-7 3.901-7 7.987V22h16v-6.938c0-4.085-3.056-7.464-7-7.986V5h4s.709.014 1.447-.355C19.186 4.275 20 3.333 20 2h-2c0 .667-.186.725-.447.855C17.29 2.986 17 3 17 3H7s-.291-.014-.553-.145C6.186 2.725 6 2.667 6 2H4zm8 9c.74 0 1.425.214 2.02.566l-2.727 2.727a.999.999 0 101.414 1.414l2.727-2.727c.352.595.566 1.28.566 2.02 0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgKitchenScales;
